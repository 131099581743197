<template>
  <v-card
    class="district-card"
    :class="{ 'line-view': lineView || city ? true : false }"
  >
    <v-card-title v-if="!lineView && !city">
      <h2>
        {{ statisticQuota.districtName || statisticQuota.district }}
      </h2>
      <template v-if="listView">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              icon
              v-on="on"
              @click="
                $emit(
                  'showDistrict',
                  statisticQuota.districtName || statisticQuota.district
                )
              "
            >
              <v-icon> mdi-open-in-new </v-icon>
            </v-btn>
          </template>
          <span>Ortsteil öffnen</span>
        </v-tooltip>
      </template>
    </v-card-title>
    <v-card-text>
      <v-row :dense="lineView || city ? false : true">
        <v-col :md="lineView || city ? 4 : 12">
          <template v-if="lineView || city">
            <h2 class="pb-2" :class="{ 'city-header': city }">
              {{
                cityHeader ||
                  statisticQuota.districtName ||
                  statisticQuota.district
              }}
              <template v-if="listView && !city">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      icon
                      v-on="on"
                      @click="
                        $emit(
                          'showDistrict',
                          statisticQuota.districtName || statisticQuota.district
                        )
                      "
                    >
                      <v-icon> mdi-open-in-new </v-icon>
                    </v-btn>
                  </template>
                  <span>Ortsteil öffnen</span>
                </v-tooltip>
              </template>
            </h2>
          </template>
          <h3 v-if="!lineView && !city">
            FTTH-Auftragseingänge
          </h3>
          <v-row no-gutters cols="12">
            <v-col v-if="!lineView && !city" md="4">
            </v-col>
            <v-col md="8">
              <v-simple-table dense>
                <tbody>
                  <!-- <tr>
                    <td>Gesamt:</td>
                    <td>
                      {{ statisticQuota.ftthContractsTotal }}
                    </td>
                  </tr> -->
                  <tr>
                    <td>Dienstaufträge:</td>
                    <td>
                      {{ statisticQuota.amountFtthServiceOrder }}
                    </td>
                  </tr>
                  <tr>
                    <td>Hausanschlüsse:</td>
                    <td>
                      {{ statisticQuota.amountFtthHc }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
        <v-col :md="lineView || city ? 4 : 12">
          <h3>Adressen</h3>
          <v-row no-gutters cols="12">
            <v-col md="4">
              <v-progress-circular
                :rotate="-90"
                :size="100"
                :width="15"
                :value="statisticQuota.ftthHcQuota * 100"
                :color="getColor(statisticQuota.ftthHcQuota)"
              >
                <template>
                  {{ formatPercent(statisticQuota.ftthHcQuota) }}
                </template>
              </v-progress-circular>
            </v-col>
            <v-col md="8">
              <v-simple-table dense>
                <tbody>
                  <tr>
                    <td>Adressen gesamt:</td>
                    <td>
                      {{ statisticQuota.addressesTotal }}
                    </td>
                  </tr>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <tr v-bind="attrs" v-on="on">
                        <td>Quote:</td>
                        <td>
                          {{ formatPercent(statisticQuota.ftthHcQuota) }}
                        </td>
                      </tr>
                    </template>
                    <span>Quote basierend auf Hausanschlüsse:
                      {{ statisticQuota.amountFtthHc }} /
                      {{ statisticQuota.addressesTotal }} (Adressen
                      gesamt)</span>
                  </v-tooltip>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
        <v-col :md="lineView || city ? 4 : 12">
          <h3>Versorgungseinheiten (VE)</h3>
          <v-row dense>
            <v-col md="4">
              <v-progress-circular
                :rotate="-90"
                :size="100"
                :width="15"
                :value="statisticQuota.ftthServiceOrderQuota * 100"
                :color="getColor(statisticQuota.ftthServiceOrderQuota)"
              >
                <template>
                  {{ formatPercent(statisticQuota.ftthServiceOrderQuota) }}
                </template>
              </v-progress-circular>
            </v-col>
            <v-col md="8">
              <v-simple-table dense>
                <tbody>
                  <tr>
                    <td>VE privat:</td>
                    <td>
                      {{ statisticQuota.supplyUnitsHomes }}
                    </td>
                  </tr>
                  <tr>
                    <td>VE gewerblich:</td>
                    <td>
                      {{ statisticQuota.supplyUnitsOffices }}
                    </td>
                  </tr>
                  <tr>
                    <td>VE gesamt:</td>
                    <td>
                      {{ statisticQuota.supplyUnitsTotal }}
                    </td>
                  </tr>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <tr v-bind="attrs" v-on="on">
                        <td>Quote:</td>
                        <td>
                          {{
                            formatPercent(statisticQuota.ftthServiceOrderQuota)
                          }}
                        </td>
                      </tr>
                    </template>
                    <span>Quote basierend auf Dienstaufträge:
                      {{ statisticQuota.amountFtthServiceOrder }}
                      /
                      {{ statisticQuota.supplyUnitsTotal }} (VE gesamt)</span>
                  </v-tooltip>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DistrictCardSimpel',
  components: {},
  props: {
    statisticQuota: {
      type: Object,
      required: true
    },
    listView: {
      type: Boolean,
      required: false,
      default: false
    },
    lineView: {
      type: Boolean,
      required: false,
      default: false
    },
    city: {
      type: String,
      required: false,
      default: undefined
    }
  },
  computed: {
    cityHeader() {
      if (this.city) {
        return this.city + ' (gesamt)';
      } else {
        return undefined;
      }
    }
  },
  watch: {
    data(newVal) {
      this.center = this.formatPoint(newVal[0].geo);
    },
    statisticQuota() {
      this.calculateQuotas();
    }
  },
  mounted() {
    this.calculateQuotas();
  },
  methods: {
    calculateQuotas() {
      this.statisticQuota.ftthHcQuota =
        this.statisticQuota.amountFtthHc > 0
          ? this.statisticQuota.amountFtthHc /
            this.statisticQuota.addressesTotal
          : 0;
      this.statisticQuota.ftthServiceOrderQuota =
        this.statisticQuota.amountFtthServiceOrder > 0
          ? this.statisticQuota.amountFtthServiceOrder /
            this.statisticQuota.supplyUnitsTotal
          : 0;
    },
    formatPercent(value) {
      if (value || value === 0) {
        value = value * 100;
        return value.toFixed(2) + ' %';
      }
    },
    getColor(item) {
      const qoute = item * 100;
      let color;
      if (qoute > 60) {
        color = '#20b200';
      } else if (qoute > 45) {
        color = '#96b200';
      } else if (qoute > 30) {
        color = '#d5d100';
      } else if (qoute > 15) {
        color = '#d58600';
      } else {
        color = '#b20000';
      }
      return color;
    }
  }
};
</script>
<style scoped lang="scss">
h2 {
  color: var(--v-primary-base);
  font-size: 26px;
}
h3 {
  border-bottom: 2px solid #ddd;
  margin: 5px 0 10px;
  padding: 5px 0;
}
.city-header {
  font-size: 32px;
}
tr td:first-child {
  width: 68%;
}
.district-card {
  max-width: 600px;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 480px;
  float: left;
}
.line-view {
  max-width: inherit;
  width: 100%;
}
</style>
