var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"district-card",class:{ 'line-view': _vm.lineView || _vm.city ? true : false }},[(!_vm.lineView && !_vm.city)?_c('v-card-title',[_c('h2',[_vm._v(" "+_vm._s(_vm.statisticQuota.districtName || _vm.statisticQuota.district)+" ")]),(_vm.listView)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit(
                'showDistrict',
                _vm.statisticQuota.districtName || _vm.statisticQuota.district
              )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-open-in-new ")])],1)]}}],null,false,2214616380)},[_c('span',[_vm._v("Ortsteil öffnen")])])]:_vm._e()],2):_vm._e(),_c('v-card-text',[_c('v-row',{attrs:{"dense":_vm.lineView || _vm.city ? false : true}},[_c('v-col',{attrs:{"md":_vm.lineView || _vm.city ? 4 : 12}},[(_vm.lineView || _vm.city)?[_c('h2',{staticClass:"pb-2",class:{ 'city-header': _vm.city }},[_vm._v(" "+_vm._s(_vm.cityHeader || _vm.statisticQuota.districtName || _vm.statisticQuota.district)+" "),(_vm.listView && !_vm.city)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit(
                        'showDistrict',
                        _vm.statisticQuota.districtName || _vm.statisticQuota.district
                      )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-open-in-new ")])],1)]}}],null,false,566933820)},[_c('span',[_vm._v("Ortsteil öffnen")])])]:_vm._e()],2)]:_vm._e(),(!_vm.lineView && !_vm.city)?_c('h3',[_vm._v(" FTTH-Auftragseingänge ")]):_vm._e(),_c('v-row',{attrs:{"no-gutters":"","cols":"12"}},[(!_vm.lineView && !_vm.city)?_c('v-col',{attrs:{"md":"4"}}):_vm._e(),_c('v-col',{attrs:{"md":"8"}},[_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Dienstaufträge:")]),_c('td',[_vm._v(" "+_vm._s(_vm.statisticQuota.amountFtthServiceOrder)+" ")])]),_c('tr',[_c('td',[_vm._v("Hausanschlüsse:")]),_c('td',[_vm._v(" "+_vm._s(_vm.statisticQuota.amountFtthHc)+" ")])])])])],1)],1)],2),_c('v-col',{attrs:{"md":_vm.lineView || _vm.city ? 4 : 12}},[_c('h3',[_vm._v("Adressen")]),_c('v-row',{attrs:{"no-gutters":"","cols":"12"}},[_c('v-col',{attrs:{"md":"4"}},[_c('v-progress-circular',{attrs:{"rotate":-90,"size":100,"width":15,"value":_vm.statisticQuota.ftthHcQuota * 100,"color":_vm.getColor(_vm.statisticQuota.ftthHcQuota)}},[[_vm._v(" "+_vm._s(_vm.formatPercent(_vm.statisticQuota.ftthHcQuota))+" ")]],2)],1),_c('v-col',{attrs:{"md":"8"}},[_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Adressen gesamt:")]),_c('td',[_vm._v(" "+_vm._s(_vm.statisticQuota.addressesTotal)+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('tr',_vm._g(_vm._b({},'tr',attrs,false),on),[_c('td',[_vm._v("Quote:")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatPercent(_vm.statisticQuota.ftthHcQuota))+" ")])])]}}])},[_c('span',[_vm._v("Quote basierend auf Hausanschlüsse: "+_vm._s(_vm.statisticQuota.amountFtthHc)+" / "+_vm._s(_vm.statisticQuota.addressesTotal)+" (Adressen gesamt)")])])],1)])],1)],1)],1),_c('v-col',{attrs:{"md":_vm.lineView || _vm.city ? 4 : 12}},[_c('h3',[_vm._v("Versorgungseinheiten (VE)")]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"md":"4"}},[_c('v-progress-circular',{attrs:{"rotate":-90,"size":100,"width":15,"value":_vm.statisticQuota.ftthServiceOrderQuota * 100,"color":_vm.getColor(_vm.statisticQuota.ftthServiceOrderQuota)}},[[_vm._v(" "+_vm._s(_vm.formatPercent(_vm.statisticQuota.ftthServiceOrderQuota))+" ")]],2)],1),_c('v-col',{attrs:{"md":"8"}},[_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('td',[_vm._v("VE privat:")]),_c('td',[_vm._v(" "+_vm._s(_vm.statisticQuota.supplyUnitsHomes)+" ")])]),_c('tr',[_c('td',[_vm._v("VE gewerblich:")]),_c('td',[_vm._v(" "+_vm._s(_vm.statisticQuota.supplyUnitsOffices)+" ")])]),_c('tr',[_c('td',[_vm._v("VE gesamt:")]),_c('td',[_vm._v(" "+_vm._s(_vm.statisticQuota.supplyUnitsTotal)+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('tr',_vm._g(_vm._b({},'tr',attrs,false),on),[_c('td',[_vm._v("Quote:")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatPercent(_vm.statisticQuota.ftthServiceOrderQuota))+" ")])])]}}])},[_c('span',[_vm._v("Quote basierend auf Dienstaufträge: "+_vm._s(_vm.statisticQuota.amountFtthServiceOrder)+" / "+_vm._s(_vm.statisticQuota.supplyUnitsTotal)+" (VE gesamt)")])])],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }